import React from "react"
import "./src/styles/styles.scss"

import { UISideEffects } from "context/ZustandStore"

export const wrapRootElement = ({ element }) => (
  <>
    <UISideEffects />
    {element}
  </>
)
