import create from "zustand"
import { mountStoreDevtool } from "simple-zustand-devtools"
import { useEffect } from "react"
import { useWindowSize } from "@react-hook/window-size"

// Main store
const useStore = create(set => ({
  isLoading: true,
  currentTheme: "light",
  setCurrentTheme: currentTheme => set({ currentTheme }),
  cursorPosition: { x: 0, y: 0 },
  setCursorPosition: (x, y) =>
    set(({ cursorPosition }) => {
      cursorPosition.x = x
      cursorPosition.y = y
    }),
  cursorType: false,
  setCursorType: cursorType => set({ cursorType }),
  cursorStyles: ["pointer", "hovered"],
  viewportSize: { height: 0, width: 0 },
  setViewport: (x, y) =>
    set(({ viewportSize }) => {
      viewportSize.width = x
      viewportSize.height = y
    }),
}))

// Initial theme frome localStorage (how to avoid FOUC?)
typeof window !== "undefined" &&
  window.localStorage.getItem("currentTheme") &&
  useStore.setState(s => ({
    currentTheme: window.localStorage.getItem("currentTheme"),
  }))

// Side effects top-level component
export const UISideEffects = () => {
  const [viewportWidth, viewportHeight] = useWindowSize({ initialWidth: 0 })
  const currentTheme = useStore(state => state.currentTheme)
  const setViewport = useStore(state => state.setViewport)

  useEffect(() => {
    setViewport(viewportWidth, viewportHeight)
  }, [viewportWidth, viewportHeight])

  useEffect(() => {
    window.localStorage.setItem("currentTheme", currentTheme)
  }, [currentTheme])

  return null
}

export default useStore

// Debug/Zustand devtools
if (typeof document !== "undefined" && process.env.NODE_ENV === "development")
  mountStoreDevtool("Store", useStore)
